import React from 'react';

const OverpaymentsPreferences = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>If you don’t contact your lender to update your overpayment preferences, you may not be saving as much as you think</li>
              <li>
              Most lenders default to reducing your monthly payments, which means the overpayments won’t be going towards the capital
              </li>
              <li>
              Call your lender to tell them you want your overpayments to reduce the capital and keep your monthly payments the same
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
              Why is it important to review and update your overpayment preferences?<br/><br/>
              If you're a homeowner in the UK making overpayments on your mortgage and you do not contact your lender to update your overpayment preferences, you might not be saving as much as you think.<br/><br/>
              Here's why. When you make overpayments, most lenders default to reducing your monthly payments. At first, it seems like a good idea, right? But here's the catch. <br/><br/>
              By reducing your monthly payments, you're not actually putting the overpayment towards the capital, and that means you're saving much less interest in the long run. But don't worry, it's an easy fix.<br/><br/>
              All you have to do is make a quick call to your lender and let them know that you want your overpayments to go towards the capital and keep the monthly payments the same.<br/><br/>
              By doing this, you'll ensure that you save the most interest and become mortgage-free faster. So why do lenders add this awkward step?<br/><br/>
              Well, it's simply because reducing your payments makes it easier for them to manage their cash flow.<br/><br/>
              So grab a pen and paper and make that call to your lender today. It only takes a few minutes and the savings can be significant.<br/><br/>
            </p>
          </div>
    </div>
  );
};

export default OverpaymentsPreferences;
